import React, { useState } from 'react'
import { compact, filter, includes, map } from 'lodash'
import { ApolloError } from '@apollo/client'
import { useRouter } from 'next/router'

import { useAdminPracticeBase } from '@docpace/admin-react-hooks'
import { PracticeStatus } from '@docpace/shared-ts-types'
import { useAdminAdminDetailQuery, useAdminBaseQuery, useAdminQueueAthenaPracticeSetUpMutation, useAdminQueueRefreshPracticeAppointmentTypeStatsMutation } from '@docpace/admin-react-apollo'
import { ctxPracticeIdAtom } from '@docpace/shared-react-atoms'
import { useAtom } from 'jotai'

interface UseAdminBaseProps {
    skip?: boolean
}

export function useAdminBase(props?: UseAdminBaseProps){
    const { skip } = props ?? {}
    
    const [ practiceId ] = useAtom(ctxPracticeIdAtom)
    const [fetchError, setFetchError] = useState<ApolloError | null>(null)
    const [queueAthenaPracticeSetup] = useAdminQueueAthenaPracticeSetUpMutation()
    const [queueRefreshPracticeAppointmentTypeStats] =
        useAdminQueueRefreshPracticeAppointmentTypeStatsMutation()
    
    const {
        loading: adminBaseLoading,
        refetch: adminBaseRefetch,
        data: adminBaseData,
    } = useAdminBaseQuery({
        skip: !!fetchError || skip,
        onError: setFetchError,
    })

    const {
        practices: practicesData,
        adminId,
        // practiceId,
        serverInfo,
    } = adminBaseData || {}
        
    const {
        isLoading: practiceLoading,
        practice,
        providers,
        departments,
        appointmentTypes,
    } = useAdminPracticeBase({ skip, practiceId })

    const { loading: adminDetailLoading, data: adminData } =
        useAdminAdminDetailQuery({
            variables: { adminId: adminId ?? '' },
            skip: !adminId || !!fetchError || skip,
            onError: setFetchError,
        })
        

    const allPractices = compact(practicesData?.nodes)
    const practices = filter(
        allPractices,
        ({ status }) => status !== PracticeStatus.DISABLED
    )
    const disabledPractices = filter(
        allPractices,
        ({ status }) => status === PracticeStatus.DISABLED
    )

    return {
        adminId: adminData?.admin?.adminId ?? null,
        practice: practice ?? null,
        allPractices,
        practices,
        disabledPractices,
        admin: adminData?.admin ?? null,
        actor: adminData?.admin?.adminActor ?? null,
        isProd: !!serverInfo?.isProd,
        webhooksServerUrl: serverInfo?.webhooksServerUrl,
        adminBaseRefetch: ()=>adminBaseRefetch().then(()=>{}),
        queueAthenaPracticeSetup,
        queueRefreshPracticeAppointmentTypeStats,
        isLoading:
            adminBaseLoading ||
            practiceLoading ||
            adminDetailLoading
    }
}

export default useAdminBase
