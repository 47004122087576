import React, { FC, useEffect, useRef, useState } from 'react'
import { Loader } from '@googlemaps/js-api-loader';

import {
    UseReactHookFormProps,
} from '@docpace/shared-react-hooks/useReactHookForm'
import { useQuery } from '@apollo/client';
import { AdminGooglePlaceByIdQueryDocument } from '@docpace/admin-graphql/dist/urql.types';
import { HeaderBackIcon } from '@docpace/shared-react-icons';
import { useAdminGooglePlaceByIdQuery } from '@docpace/admin-react-apollo';

const loader = new Loader({
    apiKey: process.env['GOOGLE_MAPS_API_KEY'],
    version: 'weekly',
    libraries: ['places', 'maps'],
});
interface AdminSearchGooglePlaceFormComponentProps
    extends Omit<UseReactHookFormProps, 'schema'> {
    onCancel: () => void
}

export const AdminSearchGooglePlaceForm: FC<
    AdminSearchGooglePlaceFormComponentProps
> = (props) => {
    const { onSubmit, onCancel } = props
    const [place, setPlace] = useState(null);
    const [searchOpts, setSearchOpts] = useState({
        fields: ["formatted_address", "geometry", "name", "place_id"],
        strictBounds: false,
        componentRestrictions: { country: "us" },
    })
    const mapRef = useRef();
    const pacAddressInputRef = useRef();
    const pacEstablishmentInputRef = useRef();
    const addressAutocompleteRef = useRef<any>();
    const establishmentAutocompleteRef = useRef<any>();
    const infoWindowRef = useRef<any>();

    const placeId = place?.place_id

    // this query is required to pre-load the google place data into the db
    const { data, loading } = useAdminGooglePlaceByIdQuery({
        variables: { placeId },
        skip: !placeId
    })

    useEffect(() => {
        let marker = null
        let map = null
        let infoWindow = null
        if (document) {
            loader.importLibrary('maps')
                .then(({ Map, InfoWindow }) => {
                    map = new Map(document.getElementById('map'), {
                        center: { lat: 29.949030, lng: -90.082298 },
                        zoom: 13,
                        clickableIcons: false,
                        streetViewControl: false,
                        fullscreenControl: false,
                        mapTypeControl: false,
                        rotateControl: false,
                    })
                    infoWindow = new InfoWindow()
                    infoWindow.setContent(infoWindowRef.current)
                    return null
                })
                .then(() => loader.importLibrary('marker'))
                .then(({ Marker }) => {
                    marker = new Marker({ map, anchorPoint: null })
                })
                .then(() => loader.importLibrary('places'))
                .then(({ SearchBox, Autocomplete }) => {
                    const pacAddress = document.getElementById('pac-address') as HTMLInputElement
                    const addressAutocomplete = new Autocomplete(pacAddress, {
                        types: ["address"],
                        ...searchOpts
                    })
                    const pacEstablishment = document.getElementById('pac-establishment') as HTMLInputElement
                    const establishmentAutocomplete = new Autocomplete(pacEstablishment, {
                        types: ["establishment"],
                        ...searchOpts
                    })
                    

                    const placeChanged = (place)=>{
                        infoWindow?.close();
                        marker.setVisible(false);
                        if (!place.geometry || !place.geometry.location) {
                            // User entered the name of a Place that was not suggested and
                            // pressed the Enter key, or the Place Details request failed.
                            window.alert("No details available for input: '" + place.name + "'");
                            return;
                        }
                        setPlace(place)
                        // If the place has a geometry, then present it on a map.
                        if (place.geometry.viewport) {
                            map.fitBounds(place.geometry.viewport);
                        } else {
                            map.setCenter(place.geometry.location);
                            map.setZoom(17);
                        }

                        marker.setPosition(place.geometry.location);
                        marker.setVisible(true);
                        infoWindow.open(map, marker)
                    }

                    addressAutocompleteRef.current = addressAutocomplete
                    addressAutocomplete.bindTo("bounds", map)
                    addressAutocomplete.addListener("place_changed", () => {
                        const place = addressAutocomplete.getPlace();
                        placeChanged(place)
                    });

                    establishmentAutocompleteRef.current = addressAutocomplete
                    establishmentAutocomplete.bindTo("bounds", map)
                    establishmentAutocomplete.addListener("place_changed", () => {
                        const place = establishmentAutocomplete.getPlace();
                        placeChanged(place)
                    });
                })
        }
    }, [window['google']])

    return (<>
        <style> {`
#infowindow-content {
  display: none;
}

#map #infowindow-content {
  display: inline;
}

.pac-card {
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  margin: 10px;
  margin-top: 0px;
  padding: 0 0.5em;
  font: 400 18px Roboto, Arial, sans-serif;
  overflow: hidden;
  font-family: Roboto;
  padding: 0;
}

.pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
  z-index: 1500 !important;
}

#pac-input:focus {
  border-color: #4d90fe;
}
`}</style>
        <div className='h-full flex flex-col'>
            <div className='w-full' id="pac-card">
                <div id="pac-container" className='w-full flex items-center space-x-2'>
                    <HeaderBackIcon className='h-8 w-8 text-black' onClick={()=>{ onCancel() }}/>
                    <div id="pac-container" className='w-full flex flex-col space-y-2' style={{ zIndex: 1500 }}>
                        <div className='w-full'>
                            <input ref={pacEstablishmentInputRef} id="pac-establishment" placeholder="Enter an establishment" autoComplete='on' className='ml-2 w-full' />
                        </div>
                        <div className='w-full'>
                            <input ref={pacAddressInputRef} id="pac-address" placeholder="Enter an address" autoComplete='on' className='ml-2 w-full' />
                        </div>
                    </div>
                </div>
            </div>
            <div ref={mapRef} id='map' className='flex-1 h-full' />
            <div ref={infoWindowRef} id="infowindow-content">
                <div className='flex flex-col'>
                    <div className="title">{place?.name}</div>
                    <div>{place?.formatted_address}</div>
                    {!loading && place && <button className='btn btn-sm mt-2' onClick={() => {
                        return onSubmit(placeId)
                    }} children='Submit' />}
                </div>
            </div>
        </div>
    </>)
}

export default AdminSearchGooglePlaceForm
