import React, { useEffect } from 'react'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { SnackbarProvider } from 'notistack'
import { ApolloProvider } from '@apollo/client'
import { useAtom } from 'jotai'

import { AdminLayout } from '@docpace/admin-react-components'
import { apolloClient, serverHostname } from '@docpace/apps/admin-app/client'
import { ctxPracticeIdAtom } from '@docpace/shared-react-atoms'

import '@docpace/apps/admin-app/tailwind.css'
import '@docpace/shared-react-styles/shared/globals.css'
import '@docpace/shared-react-styles/shared/materialUI.css'
import '@docpace/shared-react-styles/shared/cards.css'
import '@docpace/shared-react-styles/shared/tables.css'
import '@docpace/shared-react-styles/shared/layout.css'
import 'react-datetime/css/react-datetime.css'
import { useIsChangingRoute } from '@docpace/shared-react-hooks/useIsChangingRoute'
import { useNextQueryParam } from '@docpace/shared-react-hooks/useNextQueryParam'
import { useSessionSharing } from '@docpace/shared-react-hooks/useSessionSharing'

function AdminApp(appProps: AppProps) {
    useIsChangingRoute()
    const { isInitialized } = useSessionSharing()
    const practiceId = useNextQueryParam('practiceId')
    const [ , setContextPracticeId ] = useAtom(ctxPracticeIdAtom)
    useEffect(()=>{
        setContextPracticeId(practiceId)
    }, [ practiceId ])

    const { Component, pageProps } = appProps

    return (
        <ApolloProvider client={apolloClient}>
            <SnackbarProvider
                maxSnack={8}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Head>
                    <title>DOCPACE Admin</title>
                    <meta name="viewport" content="initial-scale=1.0" />
                </Head>
                <main className="app">
                    { isInitialized && <AdminLayout>
                        <Component {...pageProps} />
                    </AdminLayout> }
                </main>
            </SnackbarProvider>
        </ApolloProvider>
    )
}

export default AdminApp
